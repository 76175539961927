import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/zhannum/git/the-dark-between/src/layouts/DefaultLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`A `}<strong parentName="p">{`Bifurcator`}</strong>{` is a device invented by `}<a parentName="p" {...{
        "href": "/Enzo%20Quint",
        "title": "Enzo Quint"
      }}>{`Enzo Quint`}</a>{`, based on designs he found in `}<a parentName="p" {...{
        "href": "/Mehnateruna",
        "title": "Mehnateruna"
      }}>{`Mehnateruna`}</a>{` used by the `}<a parentName="p" {...{
        "href": "/Church%20of%20Aphothis",
        "title": "Church of Aphothis"
      }}>{`Church of Aphothis`}</a>{`. This device comes in a few different forms, but is generally used in conjuction with `}<a parentName="p" {...{
        "href": "/Lotus",
        "title": "Lotus"
      }}>{`Lotus`}</a>{` to create `}<a parentName="p" {...{
        "href": "/Liminal%20Bubble",
        "title": "Liminal Bubble"
      }}>{`Liminal Bubble’s`}</a>{``}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      